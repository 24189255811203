var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.form.property_tpl_id && _vm.form.contract_fee_setting.length > 0)?_c('div',{staticClass:"rentalShops"},[_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("商铺租赁")]),_c('div',[_vm._l((_vm.form.contract_fee_setting),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"rentalBox"},[_c('div',{staticClass:"left"},[_vm._l((item),function(list,j){return _c('div',{key:j},[(j == 0)?_c('el-form-item',{attrs:{"label":"租赁商铺","prop":`contract_fee_setting[${index}][${j}].merchants_stall_id`,"rules":{
                required: true,
                message: '请选择租赁商铺',
                trigger: 'change',
              }}},[_c('div',{},[_c('el-select',{staticClass:"labelwidth",attrs:{"filterable":"","remote":"","reserve-keyword":"","placeholder":"请选择商铺","remote-method":_vm.remoteMethod,"loading":_vm.loading},on:{"change":function($event){return _vm.merchantsChange(item, index, $event, list)}},model:{value:(list.merchants_stall_id),callback:function ($$v) {_vm.$set(list, "merchants_stall_id", $$v)},expression:"list.merchants_stall_id"}},_vm._l((_vm.dateedShop.concat(_vm.intentionshop)),function(shop){return _c('el-option',{key:shop.merchants_stall_id,attrs:{"disabled":shop.disabled,"label":shop.merchants_stall_number,"value":shop.merchants_stall_id}})}),1),_c('span',{staticClass:"public-tips"},[_vm._v(" 提示：租赁商铺在商铺管理-商铺设置中进行添加 "),_c('span',{on:{"click":function($event){return _vm.$router.push('/merchant/merchantsetting')}}},[_vm._v("点击前往")])])],1),(j == 0 && list.contract_error)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(list.contract_error)+" ")]):_vm._e(),(j == 0 && list.purpose_error)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(list.purpose_error)+" ")]):_vm._e()]):_c('el-form-item',{attrs:{"label":list.contract_fee_name,"prop":`contract_fee_setting[${index}][${j}].pay_price`,"rules":{
                required: list.required_type == 10 ? true : false,
                message: `请输入${list.contract_fee_name}`,
                trigger: 'blur',
              }}},[_c('el-input-number',{staticClass:"labelwidth",attrs:{"placeholder":`请输入${list.contract_fee_name}`,"controls":false,"min":0,"disabled":item[0].merchants_stall_id ? false : true,"type":"number"},on:{"blur":function($event){return _vm.payee($event, list)}},model:{value:(list.pay_price),callback:function ($$v) {_vm.$set(list, "pay_price", $$v)},expression:"list.pay_price"}},[_c('template',{slot:"append"},[_vm._v("元/月")])],2)],1)],1)}),_c('el-form-item',{attrs:{"label":"备注"}},[_c('el-input',{staticClass:"labelwidth",attrs:{"placeholder":"请输入备注"},model:{value:(item.remark),callback:function ($$v) {_vm.$set(item, "remark", $$v)},expression:"item.remark"}})],1)],2),(index > 0)?_c('div',{staticClass:"right"},[_c('MyButton',{attrs:{"type":"error"},on:{"click":function($event){return _vm.delRental(index)}}},[_vm._v("删除")])],1):_vm._e()]),_c('el-form-item',[_c('el-divider')],1)],1)}),(_vm.isLeaseStatus)?_c('el-form-item',[_c('el-checkbox',{model:{value:(_vm.form.lease_status),callback:function ($$v) {_vm.$set(_vm.form, "lease_status", $$v)},expression:"form.lease_status"}},[_vm._v("摊位将重复出租")])],1):_vm._e()],2),_c('el-form-item',[_c('MyButton',{on:{"click":_vm.addRental}},[_vm._v("添加")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }