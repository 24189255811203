<template>
  <div class="addcontract">
    <div class="addcontractBox">
      <el-form
        label-position="right"
        :model="form"
        ref="form"
        :rules="rules"
        label-width="180px"
      >
        <el-form-item label="合同开始时间:" prop="contract_start">
          <el-date-picker
            @change="contractStart"
            class="labelwidth"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            v-model="form.contract_start"
            placeholder="合同开始时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="合同到期时间:" prop="contract_end">
          <el-date-picker
            class="labelwidth"
            format="yyyy-MM-dd"
            @change="contractEnd"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            v-model="form.contract_end"
            placeholder="合同到期时间"
          ></el-date-picker>
        </el-form-item>
        <rentalShopsTemporary :freeSet="freeSet[0]"></rentalShopsTemporary>
        <el-form-item>
          <MyButton @click="$router.go(-1)" right v-if="type == 'edit'"
            >取消</MyButton
          >
          <MyButton :disabled="iscomplete" @click="submit">提交</MyButton>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import rentalShopsTemporary from "./components/rentalShopsTemporary.vue";
import paymentAmount from "./components/paymentAmount.vue";
import contractData from "./components/contractData.vue";
import templateField from "./components/templateField.vue";
import * as http from "../../../plugin/market";
export default {
  name: "addcontract3",
  components: {
    rentalShopsTemporary,
    paymentAmount,
    contractData,
    templateField,
  },
  props: {
    merchants_id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      navList: [{ name: "新增合同" }],
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < new Date(this.form.contract_start).getTime();
        },
      },
      contractSpecial: [],
      contractSpecialPrice: "",
      rent_holiday_date: [],
      previsible: false,
      preForm: {
        contact_name: "",
        marketName: "",
      },
      dialogFormVisible: false,
      ruleform: {
        merchantsType: 1,
        merchantsCardId: "",
        merchantsStallName: "",
        merchantsName: "",
        contactName: "",
        authCateIds: [],
        merchantsLogo: "",
        merchantsContact: "",
        merchantsCreditOnly: "",
        merchantsBusinessLicense: "",
        merchantsCertificate: "",
      },
      field: [],
      id: "",
      setType: "",
      property_setting_id: "",
      freeSet: [
        [
          {
            merchants_stall_id: "",
            total_pay_price: 0,
            pay_price: 0,
            month_pay_price: 0,
          },
          {
            id: 7,
            contract_id: 0,
            market_id: 7,
            merchants_id: 0,
            merchants_stall_id: 0,
            merchants_type_id: 0,
            merchants_room_id: 0,
            merchants_floor_id: 0,
            contract_fee_name: "商铺租金",
            is_contract_vice: 20,
            is_rent: 10,
            rent_type: 10,
            repeated_type: 10,
            update_type: 0,
            change_type: 0,
            refund_type: 0,
            required_type: 10,
            type: 10,
            pay_price: 0,
            total_pay_price: 0,
            pay_time: "",
            status: 1,
            bill_status: 0,
            parent_id: 0,
            month_pay_price: 0,
          },
        ],
      ],
    };
  },
  computed: {
    ...mapState("contract", [
      "form",
      "rules",
      "contractTemplate",
      "merchantsList",
      "reliefType",
      "contractChargeSetList",
      "iDNumber",
      "iscomplete",
      "type",
      "tempContent",
      "isLeaseStatus",
      "earnestMoney",
    ]),
  },
  async created() {
    this.initData();
    await this.getContractChargeSetList();
    await this.getContract().then((res) => {
      if (res.data.list) {
        this.modelSelect(res.data.list[0].property_setting_id);
      }
    });
    await this.getMerchantsList();
    let id = "";
    let type = "";
    id = this.$route.query.id;
    type = this.$route.query.type;
    // if (id) {
    //   this.id = id;
    //   await this.getContractDetail({id, type});
    // } else {
    //   let tempform = window.sessionStorage.getItem("addcontractform") || null;
    //   if(tempform){
    //     this.setFormDetail(JSON.parse(tempform));
    //     // this.getContract(this.form.property_tpl_id)
    //     // this.tempSelect(this.form.property_tpl_id);
    //     // this.contractStart(this.form.contract_start);
    //     // this.contractEnd(this.form.contract_end)
    //   }
    // }
    this.getTypes();
    this.getEarnestMoney();
    this.freeSet[0][1] = this.contractChargeSetList[0];
    this.form.contract_fee_setting = JSON.parse(JSON.stringify(this.freeSet));
  },
  beforeDestroy() {
    this.initData();
  },
  methods: {
    ...mapActions("contract", [
      "getContract",
      "uploadFiles",
      "getMerchantsList",
      "getContractChargeSetList",
      "getShopList",
      "getContractDetail",
      "getEarnestMoney",
    ]),
    ...mapMutations("contract", [
      "setFormData",
      "setChangeMerchants",
      "setDateedShop",
      "calculateMonthlyAmount",
      "initProgressiveIncrease",
      "calculateIncrease",
      "setIscomplete",
      "initData",
      "setIntentionshop",
      "setChangeContract",
      "setIntentionMoneyShop",
      "setMerchantsStall",
      "setFormDetail",
      "setContent",
    ]),
    checktpl() {
      let isUrl = this.form.contract_tpl.search(/https:\/\/|http:\/\//);
      if (isUrl == -1) {
        window.open(process.env.VUE_APP_LOGIN + "/" + this.form.contract_tpl);
      } else {
        window.open(this.form.contract_tpl);
      }
    },
    assemblyData() {
      if (this.form.contract_start && this.form.contract_end) {
        // let time = this.$common.monthsBetw(this.form.contract_start, this.form.contract_end);
        // let years = Math.ceil(time.months / 12);
        let years = this.$common.calculateYear(
          this.form.contract_start,
          this.form.contract_end
        );
        this.initProgressiveIncrease(years.year);
        this.calculateMonthlyAmount();
        this.calculateIncrease();
      }
      this.setIscomplete(false);
    },
    handlePreview(file) {
      if (file && file.raw) {
        let formData = new FormData();
        formData.append("file", file.raw);
        this.uploadFiles(formData);
      }
    },
    /**
     * [contractStart 选择合同开始时间，获取当前可以出租的商铺列表]
     * @param  {[type]} date               [合同开始日期]
     */
    contractStart(date) {
      if (date) {
        this.getShopList({
          endTime: date,
        });
        if (this.form.contract_end) {
          // let time = this.$common.monthsBetw(date, this.form.contract_end);
          let years = this.$common.calculateYear(date, this.form.contract_end);
          this.initProgressiveIncrease(years.year);
          // let years = Math.ceil(time.months / 12);
          // this.initProgressiveIncrease(years);
          this.calculateMonthlyAmount();
          this.calculateIncrease();
        }
      } else {
        this.setDateedShop([]);
      }
    },
    /**
     * [contractEnd 选择合同到期时间]
     * @param  {[type]} date               [description]
     * @return {[type]}      [description]
     */
    contractEnd(date) {
      if (date && this.form.contract_start) {
        // let time = this.$common.monthsBetw(this.form.contract_start, date);
        // let years = Math.ceil(time.months / 12);
        // this.initProgressiveIncrease(years);
        let years = this.$common.calculateYear(this.form.contract_start, date);
        this.initProgressiveIncrease(years.year);
        this.calculateMonthlyAmount();
        this.calculateIncrease();
      }
    },
    /**
     * [submit 新增合同提交]
     */
    submit() {
      if (this.isLeaseStatus && !this.form.lease_status) {
        this.$common.notifyError("请确认摊位是否重复出租");
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.contract_type == 10) {
            this.form.contract_no = "";
          }
          if (this.$route.query.type == "edit") {
            this.form.parent_id = this.$route.query.id;
          }
          if (this.type == "renew") {
            this.form.renew = 1;
          } else {
            this.form.renew = "";
          }
          // if(this.form.contract_type == 10){
          //     this.previsible = true;
          // } else {
          //    this.handleSubmit()
          // }
          this.handleSubmit();
        }
      });
    },
    lastInit() {
      let data = JSON.parse(JSON.stringify(this.form));
      delete data.dcList;
      let totalMoney = 0;
      for (let i = 0; i < data.contract_fee_setting.length; i++) {
        totalMoney += data.contract_fee_setting[i][1].pay_price * 1 || 0;
      }
      data.monthTotal = [this.freeSet[0][1]];
      data.contract_increasing[0].month_pay_price = totalMoney.toString();
      data.contract_increasing[0].total_pay_price = totalMoney.toString();
      data.contract_increasing[0].fees_list[0].original_pay_price =
        totalMoney.toString();
      data.contract_increasing[0].fees_list[0].pay_price =
        totalMoney.toString();
      data.contract_increasing[0].fees_list[0].total = totalMoney;
      data.contract_increasing[0].fees_list[0].contract_rentInfo[0].pay_price =
        totalMoney;
      data.contract_increasing[0].fees_list[0].contract_rentInfo = [
        data.contract_increasing[0].fees_list[0].contract_rentInfo[0],
      ];
      data.contract_increasing[0].fees_list = [
        data.contract_increasing[0].fees_list[0],
      ];
      data.contract_increasing = [data.contract_increasing[0]];
      data.total_price = data.month_price;
      console.log("整合数据", data);
      return data;
    },
    handleSubmit() {
      console.log("原始数据", {
        ...this.form,
        merchants_id: this.merchants_id,
      });
      let data = this.lastInit();
      this.$request
        .HttpPost("/contract_temporary/add", {
          ...data,
          merchants_id: this.merchants_id,
        })
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.initData();
        })
        .finally(() => {
          this.setIscomplete(false);
          this.$emit("sbumitChange");
        });
    },
    /**
     * [inquire 查询意向金]
     */
    inquire() {
      if (!this.form.property_tpl_id) {
        this.$common.notifyError("请选择合同模板");
        return;
      }
      if (!this.contractSpecial) {
        this.$common.notifyError("请输入意向金凭证号");
        return;
      }
      this.$request
        .HttpPost("/purpose/getPurposeNo", {
          purpose_nos: this.contractSpecial,
        })
        .then((res) => {
          if (res.data) {
            let tempArr = [];
            let dataArr = [];
            res.data.forEach((item) => {
              this.form.purpose_id.push(item.purpose_id);
              this.contractSpecialPrice += item.purpose_money;
              item.merchants_purpose_stall.forEach((list) => {
                list.disabled = false;
                tempArr.push({
                  merchants_stall_id: list.merchants_stall_id,
                });
                list.pitchVal = "";
                dataArr.push(list);
              });
            });
            this.setMerchantsStall(tempArr);
            this.setIntentionshop(dataArr);
            this.setIntentionMoneyShop(dataArr);
          }

          // if (res.data.pay_status == 0 && res.data.purpose_status != -1) {
          //   this.$common.notifyError('该意向金还未支付，无法使用');
          // } else {
          //   if (res.data.purpose_status != 0) {
          //     this.$common.notifyError('该意向金已使用或者已作废');
          //   } else {
          //     this.form.purpose_id = res.data.purpose_id;
          //     this.contractSpecialPrice = res.data.purpose_money;
          //     let tempArr = [];
          //     res.data.merchants_purpose_stall.forEach(item => {
          //       item.disabled = false;
          //       tempArr.push({
          //         merchants_stall_id: item.merchants_stall_id
          //       });
          //       item.pitchVal = '';
          //     });
          //     this.setMerchantsStall(tempArr);
          //     this.setIntentionshop(res.data.merchants_purpose_stall);
          //     this.setIntentionMoneyShop(res.data.merchants_purpose_stall);
          //   }
          // }
        });
    },
    rentHolidayBlur() {
      if (!this.form.contract_start) {
        this.$common.notifyError("请选择合同开始时间");
        return;
      }
      if (!this.form.contract_end) {
        this.$common.notifyError("请选择合同结束时间");
        return;
      }
      if (!this.form.rent_holiday) {
        return;
      }
      let time = this.$common.monthsBetw(
        this.form.contract_start,
        this.form.contract_end
      );
      if (parseInt(this.form.rent_holiday) < 0) {
        this.form.rent_holiday = 0;
        return false;
      }
      if (parseInt(this.form.rent_holiday) >= time.months) {
        this.$common.notifyError("免租期时长不能等于或者大于合同时长");
        this.form.rent_holiday = 0;
        return;
      }
      let date = this.$common.addMonth(
        this.form.contract_start,
        this.form.rent_holiday
      );
      this.form.contract_fee_start = this.form.contract_start;
      let end = this.$common.formatDateTime(date);
      this.$set(this.rent_holiday_date, 0, this.form.contract_start);
      this.$set(this.rent_holiday_date, 1, end);
      this.form.contract_fee_end = end;
      this.form.rent_holiday = parseInt(this.form.rent_holiday);
      let years = Math.ceil(time.months / 12);
      this.initProgressiveIncrease(years);
      this.calculateMonthlyAmount();
      this.calculateIncrease();
    },
    async tempSelect(e) {
      let data = this.contractTemplate.find(
        (item) => item.property_setting_id == e
      );
      this.setContent(e);
    },
    merchantSubmit() {
      this.$refs.ruleform.validate((valid) => {
        if (valid) {
          http.mHttpPost("/merchants/create", this.ruleform).then((res) => {
            this.dialogFormVisible = false;
            this.$common.notifySuccess("操作成功");
            this.getMerchantsList();
          });
        }
      });
    },
    getTypes() {
      http.mHttpPost("/marketFoodCate/list", { pageSize: 500 }).then((res) => {
        if (res.data) {
          this.field = res.data.list;
        }
      });
    },
    upload(file, value) {
      let form = new FormData();
      form.append("file", file.raw);
      http.mHttpPost("/system/upload/upImg", form).then((res) => {
        this.ruleform[value] = http.baseUrl + "/" + res.data.file.url;
        console.log(res);
      });
    },
    diaclose() {
      for (let i in this.ruleform) {
        this.ruleform[i] = "";
      }
      this.ruleform.merchantsType = 1;
      this.$refs.ruleform.clearValidate();
    },
    validPhone(rules, value, callback) {
      if (!this.$valide(value)) {
        return callback(new Error("请输入正确商户联系电话"));
      }
      callback();
      console.log(value);
    },
    tempSave() {
      if (!this.id) {
        window.sessionStorage.setItem(
          "addcontractform",
          JSON.stringify(this.form)
        );
      }
    },
    modelSelect(e) {
      this.setFormData({
        name: "monthTotal",
        data: [],
      });
      this.setFormData({
        name: "contract_fee_setting",
        data: [],
      });
      this.setFormData({
        name: "dcList",
        data: [],
      });
      this.setFormData({
        name: "contract_increasing",
        data: [],
      });
      this.setFormData({
        name: "merchants_stall",
        data: [],
      });
      this.setFormData({
        name: "monthTotal",
        data: [],
      });
      this.setFormData({
        name: "month_price",
        data: 0,
      });
      this.setFormData({
        name: "total_price",
        data: 0,
      });
      this.setChangeContract(e);
      // this.tempSelect(e)
    },
  },
};
</script>

<style lang="scss" scoped>
.addcontract {
  .addcontractBox {
    margin-top: 20px;
  }
  .labelwidth {
    width: 400px !important;
  }
  .public-tips {
    margin-left: 10px;
  }
}
/deep/ .el-input-number .el-input__inner {
  text-align: left;
}
.upimg {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.save {
  position: fixed;
  top: 50%;
  right: 50px;
  cursor: pointer;
}
</style>
