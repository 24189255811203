import { render, staticRenderFns } from "./merchants_temporary.vue?vue&type=template&id=29d45980&scoped=true&"
import script from "./merchants_temporary.vue?vue&type=script&lang=js&"
export * from "./merchants_temporary.vue?vue&type=script&lang=js&"
import style0 from "./merchants_temporary.vue?vue&type=style&index=0&id=29d45980&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29d45980",
  null
  
)

export default component.exports