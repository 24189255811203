import axios from 'axios';
import {
    Notification
  } from 'element-ui';
let baseUrl = 'http://test-market.muyu-tech.cn';
const fetch = function(url,method="GET",data={},responseType){
      return new Promise((resolve,reject)=>{
        const token = window.localStorage.getItem('token');
       let headers = {
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token,
      };

        axios({
            url:baseUrl+ url,
            method,
            data,
            headers,
            responseType:responseType||'json'
        })
        .then((res) => {
            if (res.status >= 400) {
              throw new Error('请求错误');
            } else {
              if (responseType && responseType == 'arraybuffer') {
                resolve(res.data);
                return ;
              }
              if (responseType && responseType == 'blob') {
               resolve(res.data);
               return ;
              }
              if (res.data.code == 0) {
                resolve(res.data);
                return ;
              }
              Notification({
                title: '错误',
                message: res.data.msg || '未知错误',
                type: 'error'
              });
              throw new Error(res);
            }
          }).catch((error) => {
            throw new Error(error);
          });
      })
        

}
const mHttpGet = async function(url,data){
     return fetch(url,'Get',data)
}

const mHttpPost = async function(url,data){
    return fetch(url,'POST',data)
}

export {mHttpGet,mHttpPost,baseUrl}