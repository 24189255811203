<template>
  <div class="merchant">
    <NavBar :list="navList" name="商户管理"></NavBar>
    <div class="merchantMain">
      <el-form :inline="true">
        <el-form-item>
          <el-autocomplete v-model="params.merchants_name" clearable :fetch-suggestions="
            (query, cb) => querySearch(query, cb, 'merchants_name')
          " placeholder="商户名称">
          </el-autocomplete>
        </el-form-item>
        <el-form-item>
          <el-autocomplete v-model="params.merchants_contact" :fetch-suggestions="
            (query, cb) => {
              querySearch(query, cb, 'merchants_contact');
            }
          " placeholder="商户电话">
          </el-autocomplete>
        </el-form-item>
        <el-form-item>
          <el-autocomplete v-model="params.merchants_number" clearable :fetch-suggestions="
            (query, cb) => querySearch(query, cb, 'merchants_number')
          " placeholder="商户号">
          </el-autocomplete>
        </el-form-item>
        <!-- <el-form-item>
          <el-autocomplete v-model="params.buding" clearable :fetch-suggestions="(query,cb)=>querySearch(query,cb,'merchants_number')"
            placeholder="楼栋"></el-autocomplete>
        </el-form-item> -->
        <el-form-item>
          <el-select v-model="params.contract_status" clearable placeholder="选择合同状态">
            <el-option label="已签订" :value="1"></el-option>
            <el-option label="未签订" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item>-->
        <!--          <el-select-->
        <!--            v-model="params.bank_status"-->
        <!--            clearable-->
        <!--            placeholder="选择银行信息状态"-->
        <!--          >-->
        <!--            <el-option label="已录入" :value="1"></el-option>-->
        <!--            <el-option label="未录入" :value="2"></el-option>-->
        <!--            <el-option label="未完成" :value="3"></el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <el-form-item>
          <el-select v-model="params.business_license_status" clearable placeholder="选择营业执照">
            <el-option label="有" :value="1"></el-option>
            <el-option label="没有" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyButton title="搜索" @click="currentChange(1)">
            <template slot="preImage">
              <img src="../../unit/img/search.png" alt="" />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton title="新增商户" :accessId="410111" @click="setEdit('add')">
            <template slot="preImage">
              <img src="@/unit/img/zj.png" alt="" />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" v-loading="loading" :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column prop="merchants_number" label="商户号"></el-table-column>
        <el-table-column prop="merchants_name" label="商户姓名"></el-table-column>
        <el-table-column prop="merchants_contact" label="商户电话"></el-table-column>
        <el-table-column label="商户类型">
          <template #default="{ row }">
            <span>{{ row.merchants_type == 1 ? "个体" : "企业" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="绑定商铺">
          <template #default="{ row }">
            {{ row.stall_message }}
          </template>
        </el-table-column>
        <el-table-column label="合同状态">
          <template #default="{ row }">
            <span class="beenSigned" v-if="row.contract_message == '已签合同'">
              已签合同
              <!-- <img src="../../assets/img/icon/yiqian.png" alt=""> -->
            </span>
            <span style="color: #e90000" v-else>未签订</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="商户姓名"></el-table-column>
       <el-table-column label="商户姓名"></el-table-column> -->
        <el-table-column prop="create_at" label="创建时间"></el-table-column>
        <el-table-column label="操作">
          <template #default="{ row }">
            <pop :accessId="410112" tips="编辑" @click="setEdit('edit', row.merchants_id)">
              <img class="icon" src="@/assets/img/icon/edit.png" alt="" />
            </pop>
            <pop popLeft :accessId="410114" tips="详情" @click="$openNewTab(`/merchants/detail?id=${row.merchants_id}`)">
              <img class="icon" src="@/assets/img/icon/xiangqing.png" alt="" />
            </pop>
            <pop popLeft :accessId="410113" tips="删除" @click="deleted(row.merchants_id)">
              <img class="icon" src="@/assets/img/icon/deleted.png" alt="" />
            </pop>
          </template>
        </el-table-column>
      </el-table>
      <div class="apiPage">
        <el-pagination :total="total" :current-page="params.pageNum" :page-size="params.pageSize"
          layout="total,prev,pager,next" next-text="下一页" prev-text="上一页" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="setType == 'add' ? '新增商户' : '编辑商户'" :visible.sync="dialogFormVisible" width="880px"
      @close="diaclose" :close-on-click-modal="false">
      <el-form :model="{
        ...ruleform,
        merchants_card_id_list,
        contact_name_list,
        merchants_contact_list,
      }" ref="ruleform" label-width="140px">
        <el-form-item label="商户身份">
          <el-radio-group :disabled="setType == 'edit'" v-model="ruleform.merchants_type">
            <el-radio :label="1">个体</el-radio>
            <el-radio :label="2">企业</el-radio>
          </el-radio-group>
        </el-form-item>

        <div class="merchant-info" v-for="(item, index) of merchants_card_id_list" :key="index - 100">
          <div class="operation">
            <MyButton right v-if="index == merchants_card_id_list.length - 1"
              @click="hanldeAddMerchantInfo('merchants_card_id_list')">
              <template slot="preImage">
                <img src="@/unit/img/zj.png" alt="" /> </template>添加
            </MyButton>
            <MyButton type="primary" v-if="merchants_card_id_list.length != 1"
              @click="hanldeDeleteMerchantInfo(merchants_card_id_list, index)">删除</MyButton>
          </div>
          <el-form-item :label="index == 0 ? '商户身份证号' : ''" :rules="{
            required: true,
            trigger: 'blur',
            validator: checkID,
          }" v-if="ruleform.merchants_type == 1" :prop="`merchants_card_id_list.${index}.merchants_card_id`">
            <el-input v-model="item.merchants_card_id" class="public-input" placeholder="请输入商户身份证号" autocomplete="off">
            </el-input>
          </el-form-item>
          <el-form-item :label="index == 0 ? '企业税号' : ''" :rules="$setRules({ message: '请输入企业税号' })" v-else
            :prop="`merchants_card_id_list.${index}.merchants_card_id`">
            <el-input v-model="item.merchants_card_id" class="public-input" placeholder="请输入企业税号" autocomplete="off">
            </el-input>
          </el-form-item>
        </div>
        <div class="merchant-info" v-for="(item, index) of contact_name_list" :key="index + 100">
          <el-form-item :label="index == 0 ? '商户姓名' : ''" :prop="`contact_name_list.${index}.contact_name`"
            :rules="$setRules({ message: '请输入商户姓名' })">
            <el-input v-model="item.contact_name" @input="ruleform.merchants_name = ruleform.contact_name"
              class="public-input" placeholder="请输入商户姓名" autocomplete="off"></el-input>
          </el-form-item>
        </div>
        <div class="merchant-info" v-for="(item, index) of merchants_contact_list" :key="index">
          <el-form-item :label="index == 0 ? '商户联系电话' : ''" :prop="`merchants_contact_list.${index}.merchants_contact`"
            :rules="{
              required: true,
              trigger: 'blur',
              validator: checkPhone,
            }">
            <el-input v-model="item.merchants_contact" class="public-input" placeholder="请输入商户联系电话" autocomplete="off">
            </el-input>
          </el-form-item>
        </div>

        <el-form-item label="商铺名称" prop="merchants_stall_name">
          <el-input v-model="ruleform.merchants_stall_name" class="public-input" placeholder="请输入商铺名称" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="商户logo">
          <el-upload action="" :show-file-list="false" :auto-upload="false" :on-change="
            (file) => {
              upload(file, 'merchants_logo');
            }
          ">
            <img class="upimg" v-if="ruleform.merchants_logo" :src="ruleform.merchants_logo" alt="" />
            <el-button v-else type="primary" icon="upload">选择商户logo</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="merchants_credit_only">
          <el-input v-model="ruleform.merchants_credit_only" class="public-input" placeholder="请输入统一社会信用代码"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="营业执照">
          <el-upload action="" :show-file-list="false" :auto-upload="false" :on-change="
            (file) => {
              upload(file, 'merchants_business_license');
            }
          ">
            <img class="upimg" v-if="ruleform.merchants_business_license" :src="ruleform.merchants_business_license"
              alt="" />
            <el-button v-else type="primary" icon="upload">选择营业执照</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="其他证件">
          <el-upload action="" :show-file-list="false" :auto-upload="false" :on-change="
            (file) => {
              upload(file, 'merchants_certificate');
            }
          ">
            <img class="upimg" v-if="ruleform.merchants_certificate" :src="ruleform.merchants_certificate" alt="" />
            <el-button v-else type="primary" icon="upload">选择其他证件</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <MyButton @click="dialogFormVisible = false" right>取 消</MyButton>
        <MyButton type="primary" @click="submit">确 定</MyButton>
      </div>
    </el-dialog>
    <el-dialog title="上传模板" :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form :model="ruleform" :rules="rules" label-width="140px">
        <el-form-item label="上传模板">
          <MyButton>上传模板</MyButton>
        </el-form-item>
        <el-form-item>
          <div style="opacity: 0.5">
            注意：摊位id为摊位唯一识别编码，请确保当前摊位为未入驻的状态下上传入驻信息，否则会入驻失败。
          </div>
        </el-form-item>
        <el-form-item>
          <MyButton>下载模板</MyButton>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <MyButton @click="dialogVisible = false" right>取 消</MyButton>
        <MyButton type="primary" @click="dialogVisible = false">确 定</MyButton>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { verifyPhone, verifyId } from "../../plugin/common";
export default {
  name: "App",
  data () {
    return {
      merchants_card_id_list: [
        {
          merchants_card_id: "",
        },
      ],
      contact_name_list: [
        {
          contact_name: "",
        },
      ],
      merchants_contact_list: [
        {
          merchants_contact: "",
        },
      ],

      navList: [
        {
          path: "/merchants",
          name: "商户管理",
        },
        {
          path: "/merchants_temporary",
          name: "临时商户",
        },
      ],
      params: {
        merchants_number: "",
        merchants_name: "",
        merchants_contact: "",
        stall_status: "",
        contract_status: "",
        bank_status: "",
        business_license_status: "",
        pageNum: 1,
        pageSize: 8,
      },
      total: 0,
      tableData: [],
      rules: {},
      dialogFormVisible: false,
      dialogVisible: false,
      ruleform: {
        merchants_type: 1,
        merchants_card_id: "",
        merchants_stall_name: "",
        merchants_name: "",
        contact_name: "",
        auth_cate_ids: [],
        merchants_logo: "",
        merchants_contact: "",
        merchants_credit_only: "",
        merchants_business_license: "",
        merchants_certificate: "",
        property_status: false,
      },
      field: [],
      setType: "add",
      loading: false,
    };
  },
  created () {
    this.getList();
    // this.getTypes()
  },
  methods: {
    checkID (rules, value, next) {
      if (verifyId(value)) {
        next();
      }
      next(new Error("请输入正确的身份证号"));
    },
    checkPhone (rules, value, next) {
      let specialPlane = /^\d{3}-\d{8}$|^\d{4}-\d{7}$|^\d{4}-\d{8}$/;
      let phone = /^1[345789]{1}\d{9}$/;
      if (specialPlane.test(value) || phone.test(value)) {
        next();
      }
      // if (verifyPhone(value)) 
      //   next();
      // }
      next(new Error("请输入正确的手机号"));
    },
    hanldeAddMerchantInfo (target) {
      this.merchants_card_id_list.push({ merchants_card_id: "" });
      this.contact_name_list.push({
        contact_name: "",
      });
      this.merchants_contact_list.push({
        merchants_contact: "",
      });
    },
    hanldeDeleteMerchantInfo (target, index) {
      this.merchants_card_id_list.splice(index, 1);
      this.contact_name_list.splice(index, 1);
      this.merchants_contact_list.splice(index, 1);
    },
    getStatus (item) {
      if (
        item.merchants_no &&
        item.collection_dynamic_number &&
        item.collection_code
      ) {
        return "银行信息已录入";
      } else if (
        item.merchants_no ||
        item.collection_dynamic_number ||
        item.collection_code
      ) {
        return "银行信息未完成";
      } else {
        return "银行信息未录入";
      }
    },
    validPhone (rules, value, callback) {
      console.log("this.$valide", this);
      if (true) {
        return callback(new Error("请输入正确商户联系电话"));
      }
      callback();
    },
    currentChange (page) {
      this.params.pageNum = page;
      this.getList();
    },
    getList () {
      this.loading = true;
      this.tableData = [];
      this.$request
        .HttpPost("/merchants/list", this.params)
        .then((res) => {
          if (res.data) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setEdit (type, id) {
      console.log(id);
      if (type == "edit") {
        this.$request.HttpGet("/merchants/edit", { id }).then((res) => {
          if (res.data) {
            this.ruleform = res.data;
            this.contact_name_list = res.data.contact_name
              ?.split("/")
              .map((item) => ({
                contact_name: item,
              }));
            this.merchants_card_id_list = res.data.merchants_card_id
              ?.split("/")
              .map((item) => ({ merchants_card_id: item }));
            this.merchants_contact_list = res.data.merchants_contact
              ?.split("/")
              .map((item) => ({
                merchants_contact: item,
              }));
            this.ruleform.propertyStatus = true;
          }
        });
      } else {
        this.ruleform.propertyStatus = false;
      }

      this.setType = type;
      this.dialogFormVisible = true;
    },
    getTypes () {
      this.$request
        .HttpPost("/marketFoodCate/list", { pageSize: 500 })
        .then((res) => {
          if (res.data) {
            this.field = res.data.list;
          }
        });
    },
    upload (file, value) {
      let form = new FormData();
      form.append("file", file.raw);
      this.$request.HttpPost("/system/upload/upImg", form).then((res) => {
        if (res.data) {
          this.ruleform[value] =
            process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl;
        }
      });
    },
    deleted (id) {
      this.$myconfirm("此操作将永久删除该商户, 是否继续?").then((_) => {
        this.$request
          .HttpPost("/merchants/delete", { ids: [id] })
          .then((res) => {
            this.$common.notifySuccess("删除成功");
            this.getList();
          });
      });
    },
    submit () {
      let url = this.setType == "add" ? "/merchants/add" : "/merchants/edit";

      this.$refs.ruleform.validate((valid) => {
        if (valid) {
          const params = {
            ...this.ruleform,
            merchants_card_id: this.merchants_card_id_list
              .map((item) => item.merchants_card_id)
              .join("/"),
            contact_name: this.contact_name_list
              .map((item) => item.contact_name)
              .join("/"),
            merchants_contact: this.merchants_contact_list
              .map((item) => item.merchants_contact)
              .join("/"),
          };
          this.$request
            .HttpPost(url, { ...params, merchants_name: params.contact_name })
            .then((res) => {
              this.dialogFormVisible = false;
              this.$common.notifySuccess("操作成功");
              this.getList();
            });
        }
      });
    },
    diaclose () {
      this.ruleform = this.$options.data().ruleform;
      this.merchants_card_id_list = [{}];
      this.merchants_contact_list = [{}];
      this.contact_name_list = [{}];
      this.$refs.ruleform.clearValidate();
    },
    async querySearch (query, cb, type) {
      let { data } = await this.$request.HttpPost(
        "/property/merchants/list",
        this.params
      );
      console.log(data);
      if (data && data.list) {
        data.list.forEach((item) => {
          item.value = item[type];
        });

        console.log(data.list);
        return cb(data.list);
      }
      return cb([]);
    },
  },
};
</script>

<style lang="scss" scoped>
.merchant {
  height: auto;
}

.merchantMain {
  // height: calc(100vh - 130px);
  padding-top: 20px;

  .public-page {
    text-align: right;
  }

  .boxList {
    //display: flex;
    //align-items: center;
    // flex-wrap: wrap;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 410px);
    grid-gap: 10px;

    // display: flex;
    // justify-content: flex-start;
    // flex-wrap: wrap;
    //  align-items: center;
    .list {
      width: 410px;
      min-height: 270px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #dedede;
      margin: 0 auto 10px 0;
      box-sizing: border-box;
      position: relative;
      flex-shrink: 0;
      padding-bottom: 10px;

      &:last-child {
        // margin-left: auto;
      }

      &.noList {
        border: 1px solid rgba(233, 0, 0, 0.3);

        .title {
          background: rgba(233, 0, 0, 0.06);
        }
      }

      .title {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 10px 0;
        background: #f9f9f9;
        font-size: 12px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;

        div {
          flex: 1;
          text-align: center;
          height: 40px;
          line-height: 40px;

          &:first-child {
            border-right: 1px dotted #eeeeee;
          }
        }
      }

      .type {
        display: flex;
        align-items: center;

        div {
          flex: 1;
          text-align: center;
          padding: 20px 0;
        }
      }

      .content {
        color: #666666;
        display: flex;

        .left {
          flex: 1;

          div {
            line-height: 24px;
            padding: 1px 20px;
            display: flex;
            align-items: center;

            .shop {
              color: #333333;
              width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: break-all;
            }

            .beenSigned {
              color: #02ce80;

              img {
                width: 14px;
                height: 14px;
                vertical-align: middle;
                margin-left: 5px;
              }
            }

            .notSign {
              color: #e90000;
            }

            .name {
              display: block;
              width: 60px;
              text-align: right;
              font-size: 12px;
              font-weight: 400;
              color: #999999;
            }
          }
        }

        .right {
          width: 110px;

          .boxImgList {
            display: block;
            width: 92px;
            height: 60px;
            border: 1px dashed #f5f5f5;

            img {
              width: 80px;
              height: 52px;
              display: block;
              margin: 4px auto;
              object-fit: contain;
            }
          }
        }
      }

      .btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        height: 62px;
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        left: 0;
        bottom: 0;
        border-top: 1px dotted rgba(222, 222, 222, 0.5);

        div {
          height: 32px;
          background: #f9f9f9;
          line-height: 32px;
          opacity: 1;
        }
      }
    }
  }
}

.upimg {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.merchant-info {
  position: relative;

  .operation {
    position: absolute;
    bottom: 0px;
    left: 350px;
    z-index: 99;
  }
}
</style>
