<template lang="html">
  <div
    class="rentalShops"
    v-if="form.property_tpl_id && form.contract_fee_setting.length > 0"
  >
    <el-divider content-position="left">商铺租赁</el-divider>
    <div>
      <div v-for="(item, index) in form.contract_fee_setting" :key="index">
        <div class="rentalBox">
          <div class="left">
            <div class="" v-for="(list, j) in item" :key="j">
              <el-form-item
                label="租赁商铺"
                v-if="j == 0"
                :prop="`contract_fee_setting[${index}][${j}].merchants_stall_id`"
                :rules="{
                  required: true,
                  message: '请选择租赁商铺',
                  trigger: 'change',
                }"
              >
                <div class="">
                  <el-select
                    v-model="list.merchants_stall_id"
                    filterable
                    remote
                    reserve-keyword
                    class="labelwidth"
                    placeholder="请选择商铺"
                    :remote-method="remoteMethod"
                    @change="merchantsChange(item, index, $event, list)"
                    :loading="loading"
                  >
                    <el-option
                      :disabled="shop.disabled"
                      v-for="shop in dateedShop.concat(intentionshop)"
                      :key="shop.merchants_stall_id"
                      :label="shop.merchants_stall_number"
                      :value="shop.merchants_stall_id"
                    >
                    </el-option>
                  </el-select>
                  <!--                  <el-select v-model="list.merchants_stall_id" @change="merchantsChange(item, index, $event, list)" class="labelwidth" placeholder="请选择商铺">-->
                  <!--                    <el-option :disabled="shop.disabled" v-for="shop in dateedShop.concat(intentionshop)" :key="shop.merchants_stall_id" :label="shop.merchants_stall_number" :value="shop.merchants_stall_id"></el-option>-->
                  <!--                  </el-select>-->
                  <span class="public-tips">
                    提示：租赁商铺在商铺管理-商铺设置中进行添加
                    <span @click="$router.push('/merchant/merchantsetting')"
                      >点击前往</span
                    >
                  </span>
                </div>
                <div v-if="j == 0 && list.contract_error" style="color: red">
                  {{ list.contract_error }}
                </div>
                <div v-if="j == 0 && list.purpose_error" style="color: red">
                  {{ list.purpose_error }}
                </div>
              </el-form-item>
              <el-form-item
                :label="list.contract_fee_name"
                v-else
                :prop="`contract_fee_setting[${index}][${j}].pay_price`"
                :rules="{
                  required: list.required_type == 10 ? true : false,
                  message: `请输入${list.contract_fee_name}`,
                  trigger: 'blur',
                }"
              >
                <el-input-number
                  :placeholder="`请输入${list.contract_fee_name}`"
                  :controls="false"
                  :min="0"
                  @blur="payee($event, list)"
                  :disabled="item[0].merchants_stall_id ? false : true"
                  v-model="list.pay_price"
                  class="labelwidth"
                  type="number"
                >
                  <template slot="append">元/月</template>
                </el-input-number>
              </el-form-item>
            </div>
            <el-form-item label="备注">
              <el-input
                placeholder="请输入备注"
                v-model="item.remark"
                class="labelwidth"
              />
            </el-form-item>
          </div>
          <div class="right" v-if="index > 0">
            <MyButton type="error" @click="delRental(index)">删除</MyButton>
          </div>
        </div>
        <el-form-item>
          <el-divider></el-divider>
        </el-form-item>
      </div>
      <el-form-item v-if="isLeaseStatus">
        <el-checkbox v-model="form.lease_status">摊位将重复出租</el-checkbox>
      </el-form-item>
    </div>
    <el-form-item>
      <MyButton @click="addRental">添加</MyButton>
    </el-form-item>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "rentalShops",
  data() {
    return {
      loading: false,
    };
  },
  props: ["freeSet"],
  computed: {
    ...mapState("contract", [
      "form",
      "rules",
      "contractTemplate",
      "merchantsList",
      "dateedShop",
      "contractChargeSetList",
      "intentionshop",
      "isLeaseStatus",
    ]),
  },
  watch: {
    "form.contract_fee_setting": function (newV) {
      console.log(newV);
    },
  },
  created() {},
  methods: {
    ...mapActions("contract", ["getShopList"]),
    ...mapMutations("contract", [
      "calculateMonthlyAmount",
      "calculateProgressiveIncrease",
      "initProgressiveIncrease",
      "calculateIncrease",
      "setIscomplete",
      "setMerchantsStall",
      "setIsLeaseStatus",
    ]),
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.getShopList({
          endTime: this.form.contract_end,
          query: query,
        })
          .then((res) => {})
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.options = [];
      }
    },
    merchantsChange(parent, index, id, list) {
      let is = false;
      this.dateedShop.concat(this.intentionshop).forEach((item) => {
        if (
          (item.contract_error || item.purpose_error) &&
          item.merchants_stall_id == id
        ) {
          list.contract_error = item.contract_error;
          list.purpose_error = item.purpose_error;
          is = true;
          return;
        }
      });
      if (!is) {
        list.contract_error = "";
        list.purpose_error = "";
      }
      this.setIsLeaseStatus(is);
      const [merchants] = this.dateedShop
        .concat(this.intentionshop)
        .filter(
          (item) => item.merchants_stall_id == parent[0].merchants_stall_id
        );
      let tempArr = this.form.merchants_stall;
      if (tempArr[index]) {
        tempArr[index] = {
          merchants_stall_id: id,
        };
      } else {
        tempArr.push({
          merchants_stall_id: id,
        });
      }
      this.setMerchantsStall(tempArr);
      // 获取当前商铺的租金
      if (parent[1]) {
        parent[1].pay_price = parseFloat(merchants.merchants_stall_price);
        this.$forceUpdate();
        this.assemblyData();
      }
    },
    addRental() {
      if (!this.form.property_tpl_id) {
        this.$common.notifyError("请先选择合同模板");
        return;
      }
      const chargeList = [
        {
          merchants_stall_id: "",
          total_pay_price: 0,
          pay_price: 0,
          month_pay_price: 0,
        },
      ];
      let [tempArr] = this.contractTemplate.filter(
        (item) => item.property_setting_id == this.form.property_tpl_id
      );
      // // 进行收费项组装
      // this.contractChargeSetList.forEach(item => {
      //   if (tempArr.values.chargingItemsId.indexOf(item.id) != -1 && item.rent_type == 10) {
      //     chargeList.push({...item});
      //   }
      // });
      this.form.contract_fee_setting.push(this.freeSet);
    },
    delRental(index) {
      this.form.contract_fee_setting.splice(index, 1);
      this.form.merchants_stall.splice(index, 1);
      if (this.form.contract_fee_setting) {
        let is = false;
        this.form.contract_fee_setting.forEach((item) => {
          item.forEach((list, j) => {
            if (j == 0 && (list.purpose_error || list.contract_error)) {
              is = true;
            }
          });
        });
        this.setIsLeaseStatus(is);
      }
    },
    payee(event, list) {
      list.pay_price = Number(event.target.value);
      this.assemblyData();
    },
    assemblyData() {
      this.calculateMonthlyAmount();
      if (this.form.contract_end) {
        let years = this.$common.calculateYear(
          this.form.contract_start,
          this.form.contract_end
        );
        this.initProgressiveIncrease(years.year);
        this.calculateIncrease();
      }
      this.setIscomplete(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.rentalBox {
  display: flex;
  align-items: center;
  .labelwidth {
    width: 400px !important;
  }
  .public-tips {
    margin-left: 10px;
  }
  .right {
    margin-left: 30px;
    flex: 1;
  }
  /deep/ .el-input-number .el-input__inner {
    text-align: left;
  }
}
</style>
